import Vue from 'vue'
import VueRouter from 'vue-router'
import { whetherMobile } from '@/utils/status'

Vue.use(VueRouter)

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}


let mobile = [
  {
    path: '/', name: 'elt-mobile', 
    redirect: { name: 'Coffee'},
    component: () => import('views/layouts/mobileLayout'),
    children: [
      { path: 'coffee', name: 'Coffee', component: () => import('views/mobile/coffee.vue') },
    ]
  },

  { path: '*', component: () => import('views/full/404') }

]

let desktop = [
	{ 
		path: '/', name: 'Eltos', redirect: { name: 'Home' },
		component: () => import('views/layouts/dashLayout'),
		children: [
			{ path: 'dash', name: 'Dash', component: () => import('views/Dash.vue') },
			{ path: 'home', name: 'Home', component: () => import('views/Home.vue') },
			{ path: 'fonts', name: 'Font', component: () => import('views/Fonts.vue') },
			{ path: 'fav', name: 'Fav', component: () => import('views/Fav.vue') },
			{ path: 'icons', name: 'Icon', component: () => import('views/Icon.vue') },
			{ path: 'wall', name: 'Wall', component: () => import('views/Wall.vue') },
			{ path: 'webdav', name: 'Dav', component: () => import('views/Webdav.vue') },
			{ path: 'bash', name: 'Bash', component: () => import('views/Bash.vue') },
		]
	},

	{ path: '*', component: () => import('views/full/404') }
]


// function whetherMobile() {
//   let uaReg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   let whether = navigator.userAgent.match(uaReg)
//   return whether == null ? false : true
// }

// const routes = whetherMobile() ? mobile : desktop
console.log('状态', whetherMobile)
const routes = whetherMobile() ? mobile : desktop

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
  routes
})

export default router
