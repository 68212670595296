<template>
	<div style="height: 160px; padding: 28px 40px;" class="flex column main-end">
		<div style="color: #ffffff;">
			<div class="flex cross-center">
				<i class="iconfont" :class="`${icon}`" :style="{'font-size': `${size || 72}px`, color: `${color || '#36373A'}`}"></i>
				<span style="font-size: 64px; margin-left: 16px;" class="wyxqn">{{title}}</span>
			</div>
		</div>
		<div style="margin-top: 16px; color: #ffffff; font-size: 14px" v-if="intro">{{intro}}</div>
		<div v-else>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'elt-header',
	props: {
		icon: String,
		title: String,
    // 简单的文字介绍，如富文本需使用插槽
		intro: String,
		size: Number,
		color: String
	},
}
</script>

<style></style>