<template>
	<i class="iconfont" :class="`${icon}`" :style="{'font-size': `${size}px`}"></i>
</template>

<script>
export default {
	name: 'elt-icon',
	props: {
		size: Number,
		icon: String,
		color: String,
	},
}
</script>

<style></style>