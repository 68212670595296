<template>
	<div class="flex" style="margin-top: 32px;">
		<div v-for="(item, index) in nav" @click="switchTab(index)" style="margin-right: 32px;" class="flex column cross-center">
			<span style="font-size: 22px; user-select: none;" :style="{color: current == index ? '#3761E1' : '#FFE5AA' }">{{item}}</span>
			<div style="height: 4px; margin: 8px; width: 24px; border-radius: 4px;" :style="{'background-color': current == index ? '#3761E1' : '' }"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'elt-navigation',
	created() {
		console.log(this.$props.nav)
	},
	data() {
		return {
			current: 0,
		}
	},
	props: {
		nav: Array
	},
	mounted() { },
	components: { },
	methods: {
		switchTab(index) {
			this.current = index
			this.$emit("switch", index)
		}
	}
}
</script>

<style>

</style>