import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';

import {get, post} from '@/utils/request.js'
import { Message } from 'element-ui';

import EltNavigation from '~/elt-navigation'
import EltIcon from '~/elt-icon'
import EltTitle from '~/elt-title'
import EltHeader from '~/elt-header'

Vue.use(Vant)

import '@/style/global.css'
import '@/style/fonts.css'

Vue.component('elt-navi', EltNavigation)
Vue.component('elt-icon', EltIcon)
Vue.component('elt-title', EltTitle)
Vue.component('elt-header', EltHeader)

Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$message = Message

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
