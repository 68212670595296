<template>
	<div style="font-size: 28px; color: #3761E1; font-weight: 700">
		<elt-icon :size="size" :icon="icon" />
		<span style="margin-left: 20px;">{{title}}</span>
	</div>
</template>

<script>

export default {
	name: 'elt-title',
	props: {
		size: String,
		icon: String,
		title: String,
	},
}
</script>

<style></style>